import React, { createContext, useContext, useReducer } from 'react';
import { isMobile } from 'react-device-detect';
import { IState } from 'interfaces/globalState.d';
import { ethers } from 'ethers';
import { ContractInterface } from '@ethersproject/contracts';
import { HubConnection } from '@microsoft/signalr';
import amplitude from 'amplitude-js';
import tokenContractAbi from 'static/abi/tokenContract.abi.json';

type ActionType = 'SET_ACCOUNT'
    | 'INITIALIZE_APPLICATION'
    | 'INIT_CONTRACT'
    | 'SET_BALANCE'
    | 'INIT_TOKEN_CONTRACT'
    | 'SET_HUB_CONNECTION'
    | 'LOG_AMPLITUDE_ACTION'
    | 'SET_CURRENT_NETWORK'
    | 'SET_USER_LOCKED_TOKENS';
type PayloadType = any;
interface IAction {
    type: ActionType;
    payload?: PayloadType;
}
type DispatchType = (action: IAction) => void;
let amplitudeProjectHandler: (value: amplitude.AmplitudeClient) => void;

const erc20Abi = tokenContractAbi;

export const StateContext = createContext<{state: IState;
  dispatch: DispatchType} | undefined>(undefined);

const stateReducer = (state: IState, action: IAction): IState => {
  const {
    abi,
    address,
    currentNetworkId,
    signer,
    account,
    balance,
    tokenAddress,
    hubConnection,
    actionName,
    amplitudeProps,
    lockedTokens,
  } = action.payload;

  switch (action.type) {
    case 'SET_ACCOUNT':
      return setAccount(state, account);
    case 'INITIALIZE_APPLICATION':
      return initialize(state, abi, address,
        currentNetworkId);
    case 'INIT_CONTRACT':
      return initContract(state, address, abi, signer);
    case 'SET_BALANCE':
      return setBalance(state, balance);
    case 'INIT_TOKEN_CONTRACT':
      return initTokenContract(state, tokenAddress);
    case 'SET_HUB_CONNECTION':
      return setHubConnection(state, hubConnection);
    case 'LOG_AMPLITUDE_ACTION':
      return logAmplitudeAction(state, actionName, amplitudeProps);
    case 'SET_CURRENT_NETWORK':
      return {
        ...state,
        currentNetworkId,
      };
    case 'SET_USER_LOCKED_TOKENS':
      return setUserLockedTokens(state, lockedTokens);
    default:
      return initialState;
  }
};

const logAmplitudeAction = (state: IState, actionName: string, props: object = {}): IState => {
  if (!state.baseUrl.includes('localhost')) {
    const tmpProps: any = { ...props };
    state.amplitudeInstance.then((instance: amplitude.AmplitudeClient) => {
      tmpProps.isMobile = isMobile;
      instance.logEvent(actionName, tmpProps);
    });
  }

  return state;
};

const getBackendUrl = (): string => {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:8080';
  }
  return `${window.location.origin}`;
};

const setAccount = (state: IState, account: string): IState => {
  if (!state.baseUrl.includes('localhost')) {
    const identify = new amplitude.Identify().setOnce('user_wallet', account);
    state.amplitudeInstance.then((instance: amplitude.AmplitudeClient) => {
      instance.identify(identify);
      return instance;
    })
      .then((instance: amplitude.AmplitudeClient) => {
        instance.logEvent('connected wallet on votes', { wallet: account });
      });
  }

  return {
    ...state,
    account,
  };
};

const setHubConnection = (state: IState, hubConnection: HubConnection): IState => ({
  ...state,
  hubConnection,
});

const setBalance = (state: IState, balance: number): IState => ({
  ...state,
  balance,
});

const initContract = (state: IState, address: string,
  abi: ContractInterface, signer: any): IState => {
  const provider = new ethers.providers.JsonRpcProvider('https://eth-goerli.g.alchemy.com/v2/9SjVwfBnSmG1O07BFKedCPjwTlrlf3m_');

  const contract: ethers.Contract = new ethers.Contract(
    address,
    abi,
    provider,
  );

  const contractWithSigner: ethers.Contract = new ethers.Contract(
    address,
    abi,
    signer,
  );

  return {
    ...state,
    contract,
    contractWithSigner,
  };
};

const setAmplitudePublicKey = (value: string): void => {
  amplitudeProjectHandler(amplitudeProjectFactory(value));
};

const amplitudeProjectFactory = (
  publicKey: string,
): amplitude.AmplitudeClient => {
  const project = amplitude.getInstance();
  project.init(publicKey);

  return project;
};

const initTokenContract = (state: IState, address: string): IState => {
  if (!state.networkProvider || !state.account) {
    throw new Error('Unable to initialize contract');
  }
  const tmpState = { ...state };

  tmpState.tokenContract = new ethers.Contract(
    address,
    erc20Abi,
    state.networkProvider.getUncheckedSigner(state.account),
  );

  return tmpState;
};

const setProvider = (state: IState, networkProvider: any): IState => {
  networkProvider.on('chainChanged', () => window.location.reload());

  return {
    ...state,
    networkProvider: new ethers.providers.Web3Provider(networkProvider),
  };
};

const initialize = (state: IState, abi: ContractInterface,
  address: string, currentNetworkId: number | null): IState => {
  let tmpState = { ...state };
  setAmplitudePublicKey('2f2926e3248ae61549e53cd759f63325');
  if (window.ethereum && (window.ethereum.isMetaMask || window.ethereum.isTrustWallet)) {
    tmpState = setProvider(state, window.ethereum);
  }

  return {
    ...tmpState,
    abi,
    address,
    currentNetworkId,
  };
};

const setUserLockedTokens = (state: IState, tokensAmount: number) => ({
  ...state,
  lockedTokens: tokensAmount,
});

const DEFAULT_NETWORK_ID = 5;

const initialState: IState = {
  account: '',
  networkProvider: null,
  currentNetworkId: parseInt(process.env.REACT_APP_NETWORK_PROVIDER_CHAIN_ID || '', 10) || DEFAULT_NETWORK_ID,
  baseUrl: getBackendUrl(),
  abi: null,
  address: '',
  contract: null,
  contractWithSigner: null,
  tokenContract: null,
  tokenContractAddress: '0xEd8c8Aa8299C10f067496BB66f8cC7Fb338A3405',
  // tokenContractAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  balance: 0,
  lockedTokens: 0,
  hubConnection: null,
  amplitudeInstance: new Promise((resolve, _) => {
    amplitudeProjectHandler = resolve;
  }),
};

const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(stateReducer, initialState);

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      { children }
    </StateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(StateContext);

  if (!context) throw new Error('useGlobalState must be used in a global state provider');

  return context;
};

export default StateProvider;
